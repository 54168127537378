import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { documentAction, loaderAction } from "../../../redux/actions";
import { getFileSize, MessageBox } from "..";
import { UploadedDocument, UploadedFile } from "../../../models";
import { convertToUploadedFileModel, handleDuplicates } from "../../helper/documentHelper";
import { useDispatch } from "react-redux";
import { fileTypes, OrganizerConstants, OrganizerUploadedDocuments, SourceDocumentConstants } from "../../../common";
import { Link } from "react-router-dom";
import { container, TYPES } from "../../../core/startup";
import { ILocalStore } from "../../../core/utilities";
import { ConflictStatusCode, CustomResponse } from "redux/reducers/uploadedDocumentStatus/model";
import { AxiosResponse } from "axios";
import CameraIcon from "../svg/CameraIconComponent";
import { useHandleFailureCallbackOnConflict } from "../hooks/useHandleFailureCallbackOnConflict";
import { UploadFunctions } from "@sssuite-js-packages/file-utility";

const LocalStorageService = container.get<ILocalStore>(TYPES.ILocalStore);

interface InputFileProps {
    image: any;
    documentData: any;
    uploadedDocuments: any;
    isCompleted: boolean;
    setCompleted: () => void;
}

export const InputFile: React.FC<InputFileProps> = ({ image, documentData, uploadedDocuments, isCompleted, setCompleted }) => {
    const params: any = useParams();
    const dispatch: any = useDispatch();
    const handleFailureCallbackOnConflict = useHandleFailureCallbackOnConflict();
    const [files, setFiles] = useState([] as any[]);

    let uploadedFiles: UploadedFile[];
    const count: number = documentData?.uploadCount;

    const onChangeHandler = (event: any) => {
        const selectedFiles = Object.values(event?.target.files);
        const validFiles = selectedFiles.filter((file: any) => fileTypes.includes(file.type));
        if (validFiles.length !== selectedFiles.length) {
            MessageBox.Error(OrganizerUploadedDocuments.SupportedFilesMessage);
        }
        setFiles(validFiles);
    };

    const callLater = (): void => {
        dispatch(
            documentAction.getBookmarks(
                params.id,
                LocalStorageService.getItemByUniqueKey(params.id, SourceDocumentConstants.SourceDocumentEnabled)
            )
        );
        dispatch(documentAction.getUploadedDocuments(params.id));
        dispatch(loaderAction.stop());
    };

    const handleAddDocument = (fileName: string, fileSize: string, uploadFiles: number, fileUploaded: number) => {
        let uploadedDocument: UploadedDocument = UploadedDocument.create(
            fileName, fileSize,
            !!documentData.formId ? documentData.formId : null
        );

        fileUploaded === uploadFiles
            ? dispatch(
                documentAction.addUploadedDocument(
                    params.id,
                    uploadedDocument,
                    () => { },
                    () => { },
                    () => { handleFailureCallbackOnConflict(params.id) }
                )
            ).then(() => callLater())
            : dispatch(documentAction.addUploadedDocument(params.id, uploadedDocument));
        setFiles([]);
    };

    const getUploadLink = () => {
        let fileUploaded: number = 0;
        let fileInError: number = 0;
        const uploadFiles: number = uploadedFiles.length;
        let fileUploadUtilities = new UploadFunctions()
        uploadedFiles.forEach((file: UploadedFile) => {
            file.name = file.name.trim();
            dispatch(
                documentAction.getUploadSasUrl(
                    params.id,
                    file.name,
                    (response: AxiosResponse<CustomResponse<any>>) => {


                        if (response.data.statusCode == ConflictStatusCode) {

                            fileInError++;

                            if (fileInError == uploadFiles) {

                                setCompleted();
                                dispatch(loaderAction.stop());

                            }
                            return;
                        }

                        fileUploadUtilities.uploadFile(
                            file.file,
                            response.data.data,
                            file.name ? file.name : "",
                            () => null,
                            () => {
                                fileUploaded++;
                                handleAddDocument(file.name, getFileSize(file.file.size), uploadFiles, fileUploaded);
                            }
                        );
                    },
                    () => { handleFailureCallbackOnConflict(params.id) })
            );
        });
    };

    const getSectionName = () => {
        return documentData?.bookMark === "Others" ? "" : `${documentData?.bookMark}_`;
    }

    const handleAddFiles = (files: any) => {
        uploadedFiles = convertToUploadedFileModel(files, getSectionName());
        if (uploadedFiles.length !== files.length) {
            MessageBox.Error(OrganizerUploadedDocuments.SupportedFilesMessage);
        }
        uploadedFiles = handleDuplicates(uploadedFiles, uploadedDocuments);

        if (uploadedFiles.length > 0) {
            getUploadLink();
        }
    };

    const handleClick = () => {
        dispatch(documentAction.setFormId(documentData?.formId, documentData?.bookMark));
    };

    useEffect(() => {
        if (files.length) {
            dispatch(loaderAction.start());
            handleAddFiles(files);
        }
    }, [files]);

    return (
        <>
            <form data-testid="uploadDocForm" className="upload-documents-form">
                <label>
                    <input
                        data-testid="uploadDocInput"
                        type="file"
                        multiple={true}
                        onChange={onChangeHandler}
                        disabled={isCompleted}
                        accept="image/png, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                    <CameraIcon color={isCompleted ? '#8bd258a6' : '#88C656'} width="1.6rem" height="15px" />
                </label>
            </form>
            {count ? (
                <Link
                    className="right"
                    style={{ paddingLeft: '20px' }}
                    to={`${OrganizerConstants.ViewDocumentsPageURL + params.id}`}
                    onClick={handleClick}
                >
                    {count} uploads
                </Link>
            ) : (
                <div className="right"></div>
            )}
        </>
    );
};
